.header {
  padding-top: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .header h3 {
    margin: 0;
    margin-bottom: 15px;
    font-family: 'Open Sans', sans-serif;
    font-size: 24px;
    font-weight: normal;
    color: #000; }
  .header p {
    margin: 0;
    margin-bottom: 15px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #000;
    text-align: center; }

.icon {
  margin-bottom: 20px;
  font-size: 50px;
  color: #349943;
  background: #fff; }
