.listGroup {
  display: flex;
  flex-direction: column;
  padding-left: 0;
  margin-bottom: 0;
  border-radius: 0.25rem; }

.listGroupItem {
  position: relative;
  display: block;
  border: 1px solid #adadb2;
  text-align: start; }

.listGroupItem + .listGroupItem {
  border-top-width: 0; }

.listGroupItem:first-child {
  border-top-left-radius: inherit;
  border-top-right-radius: inherit; }

.listGroupItem:last-child {
  border-bottom-right-radius: inherit;
  border-bottom-left-radius: inherit; }

.typeaheadInput {
  display: block;
  width: 100%; }

.typeaheadFormGroup {
  position: relative;
  width: 100%;
  height: auto; }

.typeaheadListGroup {
  position: absolute;
  top: '100%';
  min-width: 100%;
  overflow-y: scroll;
  margin-bottom: 10px;
  z-index: 1; }

.typeaheadListGroupItem {
  white-space: nowrap;
  padding: 0.6em;
  background-color: #fff; }

.typeaheadListGroupItem:hover {
  cursor: pointer;
  background: #cdcdcc;
  color: #fff; }

.typeaheadSpinnerContainer {
  position: absolute;
  width: 100%;
  height: auto;
  background-color: #fff;
  display: flex;
  padding: 5px;
  justify-content: center;
  align-items: center;
  border: 1px solid rgba(0, 0, 0, 0.125);
  z-index: 2;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem; }

.clearButton {
  position: absolute;
  top: 0;
  right: 0.3em;
  height: 100%;
  color: #545455;
  cursor: pointer; }

.spinnerBox {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  right: 1px;
  height: 100%;
  opacity: 0.7; }
