.btn {
  padding: 10px 15px;
  width: 100%;
  display: block;
  font-family: 'Open Sans', sans-serif;
  font-weight: bold;
  font-size: 24px;
  border: 1px solid transparent;
  border-radius: 12px; }

.blue {
  color: #fff;
  border-color: #33669d;
  background: #33669d; }

.gray {
  color: #000;
  border-color: #cdcdcc;
  background: #cdcdcc; }

.white {
  color: #999;
  border-color: #999;
  background: #fff; }

.btn:focus {
  outline: none;
  box-shadow: 0 0 0 2px #ff9933; }

.btn:disabled {
  border: 1px solid #545455;
  background: #cdcdcc;
  color: #545455;
  cursor: default; }

@media screen and (min-width: 600px) {
  .btn {
    margin: 0 auto;
    padding: 18px 25px;
    width: auto; } }
