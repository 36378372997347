.prompt {
  text-align: center;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #000; }

.btnContainer {
  margin: 20px 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px; }

.btn {
  position: relative;
  padding: 10px;
  flex: 1 1 33%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #33669d;
  font-size: 50px;
  border-radius: 12px;
  border: 2px solid #545455;
  background: #fff;
  /*
    This is kinda hacky but basically we don't have access to a free icon
    that matches the exact spec of the mockup so I am making due with
    what we have and hoping no one notices...
  */ }
  .btn:focus {
    outline: none; }
  .btn[data-messagedots='true']::before {
    content: '\2026';
    position: absolute;
    top: -5px;
    width: 100%;
    z-index: 2;
    color: #fff;
    font-size: 40px;
    font-family: 'Roboto', sans-serif; }
  .btn.selected {
    border: 2px solid #33669d;
    background: #eaeffa; }
  .btn span {
    margin-top: 10px;
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #000; }

.miniForm {
  margin: 20px 0;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
  gap: 10px; }

.input {
  border: 1px solid transparent;
  border-bottom-color: #cdcdcc;
  background: #fff;
  font-size: 18px;
  font-family: 'Roboto', sans-serif;
  color: #000; }

.send {
  padding: 6px 15px;
  background: #33669d;
  border-radius: 5px;
  font-family: 'Open Sans', sans-serif;
  color: #fff;
  font-weight: bold;
  font-size: 20px; }
  .send:disabled {
    color: #545455;
    background: #cdcdcc; }
