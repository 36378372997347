.error {
  border: 1px solid #df2133;
  border-radius: 5px;
  padding: 1rem;
  background-color: #ff7979;
  font-size: 18px;
  color: #000; }

.container {
  position: relative;
  display: inline-block;
  width: 380px;
  height: 550px; }

.iFrame {
  width: 100%;
  height: 100%; }
