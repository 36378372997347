.container p {
  text-align: center;
  font-family: 'Roboto', sans-serif;
  font-size: 22px;
  color: #000; }

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .header .crossmark {
    margin: 0;
    margin-bottom: 10px;
    height: 44px;
    width: 44px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #df2133;
    border-radius: 50%;
    color: #fff;
    font-size: 30px;
    font-weight: bold; }
  .header h3 {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: #000; }
