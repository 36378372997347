.container {
  margin-bottom: 20px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 20px; }

.img {
  max-width: 100%;
  grid-column: 1 / 2; }

.date,
.details {
  font-family: 'Roboto', sans-serif;
  color: #000; }

.date {
  grid-column: 2 / 3;
  text-align: right;
  font-size: 14px;
  color: #000; }

.details {
  grid-column: 1 / 3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.details p,
.details h3 {
  margin: 0;
  line-height: 1.25em;
  color: #000; }

.details h3 {
  margin-bottom: 5px;
  font-family: 'Open Sans', sans-serif;
  font-size: 24px;
  font-weight: bold;
  text-align: center; }

.details p {
  font-size: 12px; }
