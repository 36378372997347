.container {
  margin: 0 auto;
  padding: 15px;
  width: 100%;
  max-width: 600px;
  background: #fff;
  border-radius: 12px;
  box-shadow: 0 4px 5px rgba(0, 0, 0, 0.45); }

@media screen and (min-width: 600px) {
  .container {
    padding: 20px;
    border-radius: 20px; } }
