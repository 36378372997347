.container {
  margin-bottom: 20px; }

.containerCollapsed {
  margin-bottom: 0px; }

.header {
  margin-bottom: 2px;
  display: grid;
  grid-template-columns: 1fr 1fr;
  align-items: flex-end;
  column-gap: 2px; }

.header h3,
.header p {
  margin: 0;
  color: #000; }

.header h3 {
  font-family: 'Open Sans', sans-serif;
  font-weight: normal;
  font-size: 22px; }

.headerTotal {
  text-align: right;
  font-family: 'Roboto', sans-serif;
  font-weight: bold; }

.id {
  grid-column: 1 / 3;
  font-size: 14px;
  font-weight: normal;
  font-family: 'Roboto', sans-serif;
  color: #545455; }

.btn {
  margin: 15px 0;
  padding: 5px 3px 5px 0;
  font-family: 'Roboto', sans-serif;
  font-size: 18px;
  background: transparent;
  border-style: none;
  border: 1px solid transparent;
  text-transform: none;
  overflow: visible; }

.table {
  width: 100%;
  max-width: 100%;
  border-collapse: collapse; }
  .table thead {
    border-bottom: 1px solid #cdcdcc; }
  .table thead::after {
    content: '';
    height: 10px;
    width: 100%;
    display: block; }
  .table tr {
    margin-bottom: 10px; }
  .table th {
    font-family: 'Open Sans', sans-serif;
    font-size: 14px;
    font-weight: normal;
    color: #545455; }
  .table th,
  .table td {
    color: #545455;
    font-size: 14px;
    text-align: left; }
  .table td,
  .table td * {
    vertical-align: top;
    font-family: 'Roboto', sans-serif; }
  .table th:not(:first-child),
  .table td:not(:first-child) {
    padding-left: 5px; }
  .table .description {
    overflow-wrap: break-word;
    word-wrap: break-word;
    word-break: break-word; }
  .table tfoot td {
    color: #000;
    font-weight: bold; }
  .table .spacer {
    margin: 0;
    padding: 6px 0;
    width: 100%;
    display: block; }
  .table .itemPrice,
  .table .tFootTotal {
    text-align: right; }
