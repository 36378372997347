@import url(~fontsource-roboto/400.css);
.container {
  margin: 0 auto;
  padding: 20px;
  width: 100%;
  max-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 14px;
  font-family: 'Roboto', sans-serif; }
  .container p {
    margin: 0;
    margin-bottom: 10px; }
  .container img {
    max-width: 100%; }
