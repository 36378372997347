.container {
  margin-top: 20px;
  margin-bottom: 20px; }
  .container p {
    text-align: center;
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    color: #000;
    margin-bottom: 15px; }
  .container a:link,
  .container a:visited {
    font-weight: bold;
    color: #33669d; }

.header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .header h3 {
    margin: 0;
    font-family: 'Open Sans', sans-serif;
    font-weight: normal;
    font-size: 24px;
    text-align: center;
    color: #000; }
  .header h4 {
    font-family: 'Roboto', sans-serif;
    font-weight: normal;
    font-size: 14px;
    text-align: center;
    color: #000; }

.icon {
  margin-bottom: 20px;
  font-size: 50px;
  color: #349943;
  background: #fff; }
