@keyframes ldsRing {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

.auto {
  display: inline-block;
  position: relative;
  width: 1.3em;
  height: 1.3em; }
  .auto div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(1.3em * 0.8);
    height: calc(1.3em * 0.8);
    margin: calc(1.3em * 0.1);
    border: calc(1.3em * 0.1) solid #33669d;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #33669d transparent transparent transparent; }
  .auto div:nth-child(1) {
    animation-delay: -0.45s; }
  .auto div:nth-child(2) {
    animation-delay: -0.3s; }
  .auto div:nth-child(3) {
    animation-delay: -0.15s; }

.sm {
  display: inline-block;
  position: relative;
  width: 40px;
  height: 40px; }
  .sm div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(40px * 0.8);
    height: calc(40px * 0.8);
    margin: calc(40px * 0.1);
    border: 5px solid #33669d;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #33669d transparent transparent transparent; }
  .sm div:nth-child(1) {
    animation-delay: -0.45s; }
  .sm div:nth-child(2) {
    animation-delay: -0.3s; }
  .sm div:nth-child(3) {
    animation-delay: -0.15s; }

.md {
  display: inline-block;
  position: relative;
  width: 50px;
  height: 50px; }
  .md div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(50px * 0.8);
    height: calc(50px * 0.8);
    margin: calc(50px * 0.1);
    border: 6.5px solid #33669d;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #33669d transparent transparent transparent; }
  .md div:nth-child(1) {
    animation-delay: -0.45s; }
  .md div:nth-child(2) {
    animation-delay: -0.3s; }
  .md div:nth-child(3) {
    animation-delay: -0.15s; }

.lg {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px; }
  .lg div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    width: calc(80px * 0.8);
    height: calc(80px * 0.8);
    margin: calc(80px * 0.1);
    border: 8px solid #33669d;
    border-radius: 50%;
    animation: ldsRing 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: #33669d transparent transparent transparent; }
  .lg div:nth-child(1) {
    animation-delay: -0.45s; }
  .lg div:nth-child(2) {
    animation-delay: -0.3s; }
  .lg div:nth-child(3) {
    animation-delay: -0.15s; }
