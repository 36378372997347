.container {
  margin-bottom: 20px;
  display: flex;
  flex-direction: column; }

.btn {
  padding: 10px;
  display: grid;
  grid-template-columns: 22px 1.3fr 1fr;
  column-gap: 10px;
  border: 2px solid #adadb2;
  border-radius: 5px;
  background: #fff;
  cursor: pointer;
  min-height: 80px;
  align-items: center; }
  .btn .firstCol {
    margin-left: 10px; }
  .btn .secondCol {
    margin-left: 20px; }
  .btn:not(:last-child) {
    margin-bottom: 20px; }
  .btn.btnSelected {
    border-color: #33669d;
    background: #eaeffa; }
  .btn img {
    min-width: 50px;
    width: 50px; }
  .btn p {
    margin: 0;
    text-align: left;
    font-size: 14px; }
  .btn .heading,
  .btn .description {
    white-space: nowrap; }
  .btn .heading {
    color: black;
    font-family: 'Open Sans', sans-serif;
    font-size: 20px;
    font-weight: bold;
    margin-bottom: 8px; }
  .btn .description {
    font-family: 'Roboto', sans-serif;
    font-size: 14px;
    color: #999;
    line-height: 1.25em; }
  .btn .amount {
    font-family: 'Open Sans', sans-serif;
    font-size: 32px;
    font-weight: bold;
    margin-bottom: 10px; }
    .btn .amount sup,
    .btn .amount sub {
      font-weight: normal;
      font-size: 50%;
      color: #545455; }
    .btn .amount sup {
      top: -1em; }

.btn:focus {
  outline: none; }

.circle {
  width: 22px;
  font-size: 22px;
  color: #adadb2;
  background: transparent; }
  .circle.circleSelected {
    color: #33669d; }

.isPopular {
  width: fit-content;
  padding: 5px 10px;
  display: flex;
  align-items: center;
  background: #349943;
  border-radius: 500px;
  color: #fff;
  font-size: 14px; }

.savedCard {
  grid-template-columns: 22px 0fr 4fr 0fr; }
  .savedCard .topRow {
    margin-bottom: 3px !important; }
  .savedCard .placeHolder {
    visibility: hidden; }
