.container {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: rgba(255, 255, 255, 0.55);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.heading {
  color: #33669d;
  font-size: 18px; }
